import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  signal,
} from '@angular/core';
import { RoutesStore } from '@fieldos/store/data-source-cache';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SectionBasedEntityListAGGridContext } from '../../../section-columns.models';

@Component({
  selector: 'app-work-order-set-cell-renderer',
  templateUrl: './work-order-set-cell-renderer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkOrderSetCellRendererComponent
  implements ICellRendererAngularComp
{
  constructor() {
    effect(
      () => {
        const routeId = this._routeId();
        const routes = this._store.entityMap();

        this.value.set(routes[routeId]?.name || '');
      },
      { allowSignalWrites: true }
    );
  }

  protected readonly value = signal('');

  private readonly _routeId = signal(0);
  private readonly _store = inject(RoutesStore);

  agInit(
    params: ICellRendererParams<
      string,
      number,
      SectionBasedEntityListAGGridContext
    >
  ): void {
    this._setValue(params);
  }

  refresh(params: ICellRendererParams<string, number>): boolean {
    this._setValue(params);
    return true;
  }

  private _setValue(params: ICellRendererParams<string, number>): void {
    this._routeId.set(params.value || 0);
  }
}
