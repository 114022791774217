import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { WorkOrderRoute } from '@fieldos/models';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';
import { FilterChangedEvent, IFloatingFilterParams } from 'ag-grid-community';
import { debounceTime, tap } from 'rxjs';
import { WorkOrderSetFilterComponent } from '../work-order-set-filter/work-order-set-filter.component';

@Component({
  selector: 'app-work-order-set-floating-filter',
  templateUrl: './work-order-set-floating-filter.component.html',
  standalone: true,
  imports: [ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        height: auto;
        margin-top: auto;
        margin-bottom: auto;
      }
    `,
  ],
})
export class WorkOrderSetFloatingFilterComponent
  implements IFloatingFilterAngularComp
{
  constructor() {
    this.searchFormCtrl.valueChanges
      .pipe(
        debounceTime(200),
        tap(() => {
          if (!this.searchFormCtrl.value) {
            // Remove the filter
            this._params.parentFilterInstance((instance: any) => {
              const setFilter = instance as WorkOrderSetFilterComponent;

              if (setFilter && setFilter.onFloatingFilterChanged) {
                setFilter.onFloatingFilterChanged(this.searchFormCtrl.value);
              }
            });
            return;
          }

          this._params.parentFilterInstance((instance: any) => {
            const setFilter = instance as WorkOrderSetFilterComponent;

            if (setFilter && setFilter.onFloatingFilterChanged) {
              setFilter.onFloatingFilterChanged(this.searchFormCtrl.value);
            }
          });
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  protected readonly searchFormCtrl = new FormControl('', {
    nonNullable: true,
  });

  protected readonly routes = signal<WorkOrderRoute[]>([]);

  private _params!: IFloatingFilterParams;

  agInit(params: IFloatingFilterParams<any, any, any>): void {
    this._params = params;
  }

  onParentModelChanged(
    parentModel: any,
    filterChangedEvent?: FilterChangedEvent | null
  ): void {
    if (typeof parentModel === 'string') {
      return;
    } else {
      this.searchFormCtrl.setValue('', { emitEvent: false });
    }
  }

  onInputBoxChanged() {
    if (!this.searchFormCtrl.value) {
      // Remove the filter
      this._params.parentFilterInstance((instance: any) => {
        instance.myMethodForTakingValueFromFloatingFilter(null);
      });
      return;
    }

    this._params.parentFilterInstance((instance: any) => {
      instance.myMethodForTakingValueFromFloatingFilter(
        this.searchFormCtrl.value
      );
    });
  }
}
