import { AsyncPipe } from '@angular/common';
import { Component, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';
import { IFloatingFilterParams } from 'ag-grid-community';
import { debounceTime, tap } from 'rxjs';
import { FloatingFilterLayoutComponent } from '../../layouts';
import { OptionsFilterComponent } from '../options-filter/options-filter.component';

interface IOptionsFloatingFilterParams
  extends IFloatingFilterParams<any, any, any> {
  disableFloatingFilter?: boolean;
}

@Component({
  selector: 'app-options-floating-filter',
  templateUrl: './options-floating-filter.component.html',
  standalone: true,
  imports: [
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    AsyncPipe,
    TranslocoModule,
    FloatingFilterLayoutComponent,
  ],
  styles: [
    `
      :host {
        height: auto;
        margin-top: auto;
        margin-bottom: auto;
      }
    `,
  ],
})
export class OptionsFloatingFilterComponent
  implements IFloatingFilterAngularComp
{
  constructor() {
    this.searchFormCtrl.valueChanges
      .pipe(
        debounceTime(200),
        tap(() => {
          this.isParentFilterActive.set(false);
          if (!this.searchFormCtrl.value) {
            // Remove the filter
            this._params()?.parentFilterInstance((instance: any) => {
              const setFilter = instance as OptionsFilterComponent;

              if (setFilter && setFilter.onFloatingFilterChanged) {
                setFilter.onFloatingFilterChanged(this.searchFormCtrl.value);
              }
            });
            return;
          }

          this._params()?.parentFilterInstance((instance: any) => {
            const setFilter = instance as OptionsFilterComponent;

            if (setFilter && setFilter.onFloatingFilterChanged) {
              setFilter.onFloatingFilterChanged(this.searchFormCtrl.value);
            }
          });
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  protected readonly isParentFilterActive = signal(false);

  protected readonly searchFormCtrl = new FormControl('', {
    nonNullable: true,
  });

  private readonly _params = signal<IOptionsFloatingFilterParams | null>(null);

  agInit(params: IOptionsFloatingFilterParams): void {
    this._params.set(params);

    if (params.disableFloatingFilter) {
      this.searchFormCtrl.disable();
    }
  }

  onParentModelChanged(parentModel: number[] | null): void {
    if (!parentModel) {
      this.isParentFilterActive.set(false);
      return;
    }

    if (typeof parentModel === 'string') {
      return;
    } else {
      this.isParentFilterActive.set(true);
      this.searchFormCtrl.setValue('', { emitEvent: false });
    }
  }

  protected onClear(): void {
    this.isParentFilterActive.set(false);
    this.searchFormCtrl.setValue('', { emitEvent: false });
    this._params()?.parentFilterInstance((instance: any) => {
      const setFilter = instance as OptionsFilterComponent;

      setFilter.clearModel && setFilter.clearModel();
    });
  }
}
