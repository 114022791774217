export type Claim =
  | 'requests.add'
  | 'requests.edit'
  | 'requests.list'
  | 'contractors.list'
  | 'contractors.add'
  | 'contractors.delete'
  | 'contractors.edit'
  | 'contractors.advanced'
  | 'contractors.simple'
  | 'assets.list'
  | 'assets.add'
  | 'assets.delete'
  | 'assets.edit'
  | 'assets.advanced'
  | 'assets.simple'
  | 'locations.list'
  | 'locations.add'
  | 'locations.delete'
  | 'locations.edit'
  | 'locations.advanced'
  | 'locations.simple'
  | 'customers.list'
  | 'customers.add'
  | 'customers.delete'
  | 'customers.advanced'
  | 'customers.simple'
  | 'customers.edit'
  | 'users.list'
  | 'users.add'
  | 'users.delete'
  | 'users.edit'
  | 'users.advanced'
  | 'users.simple'
  | 'administration.categorytypes.list'
  | 'administration.categorytypes.add'
  | 'administration.categorytypes.edit'
  | 'administration.categorytypes.delete'
  | 'administration.locationdesigner.edit'
  | 'administration.contractordesigner.edit'
  | 'administration.assetdesigner.edit'
  | 'administration.userdesigner.edit'
  | 'administration.dpustatuses.list'
  | 'administration.dpustatuses.add'
  | 'administration.dpustatuses.edit'
  | 'administration.dpustatuses.delete'
  | 'administration.reasoncodes.list'
  | 'administration.reasoncodes.add'
  | 'administration.reasoncodes.edit'
  | 'administration.reasoncodes.delete'
  | 'administration.assetstatuses.list'
  | 'administration.assetstatuses.add'
  | 'administration.assetstatuses.edit'
  | 'administration.assetstatuses.delete'
  | 'administration.packaging.list'
  | 'administration.packaging.add'
  | 'administration.packaging.edit'
  | 'administration.packaging.delete'
  | 'administration.ordertypes.list'
  | 'administration.ordertypes.add'
  | 'administration.ordertypes.edit'
  | 'administration.ordertypes.delete'
  | 'administration.customerdesigner.edit'
  | 'dashboard.list'
  | 'inbox.list'
  | 'workorders.list'
  | 'workorders.add'
  | 'assets.file.export'
  | 'assets.file.import'
  | 'workorderslocation.list'
  | 'planner.list'
  | 'plans.list'
  | 'reports.list'
  | 'reports.add'
  | 'reports.edit'
  | 'reports.saveastemplate'
  | 'reports.delete'
  | 'workorderslocation.route.list'
  | 'workorderslocation.route.add'
  | 'workorderslocation.route.reorder'
  | 'workorderslocation.route.delete'
  | 'workorderslocation.route.edit'
  | 'workorderslocation.dpu.list'
  | 'workorderslocation.dpu.add'
  | 'teams.list'
  | 'teams.add'
  | 'teams.edit'
  | 'teams.delete'
  | 'workorderslocation.reason.code.add'
  | 'workorder.reason.code.add'
  | 'workorder.dpusection.reason.code.add'
  | 'workorder.bulkaction.add.to.route'
  | 'workorder.download.dpu.code'
  | 'workorder.email.to.location.dpu.code'
  | 'workorders.chat.upload.files'
  | 'administration.configureworkordersections.edit'
  | 'administration.requesttemplates.list'
  | 'administration.requesttemplates.add'
  | 'administration.requesttemplates.edit'
  | 'administration.requesttemplates.delete'
  | 'administration.workorderstatusgraph.list'
  | 'administration.workorderstatusgraph.add'
  | 'administration.workorderstatusgraph.edit'
  | 'administration.requeststatusgraph.list'
  | 'administration.checklists.list'
  | 'administration.workorderrights.list'
  | 'administration.requestrights.list'
  | 'workorders.file.import';
