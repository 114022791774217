import { RightsStatusType } from '../sections';
import { ChecklistEntryType } from './checklist-entry-type';

export type ChecklistTaskProperties =
  | ChecklistTaskPropertiesBase
  | ChecklistTaskOptionsProperties;

export interface ChecklistTaskPropertiesBase {
  mandatory: boolean;
  defaultValue: ChecklistTaskDefaultValue;
}

export interface ChecklistTaskOptionsProperties {
  options: string[];
}

export type ChecklistTaskDefaultValue = number | boolean | string;

export interface ChecklistEntry<
  T extends ChecklistTaskPropertiesBase = ChecklistTaskPropertiesBase,
> {
  id: string;
  name: string;
  description: string;
  type: ChecklistEntryType;
  mandatory: boolean;
  defaultValue: ChecklistTaskDefaultValue;
  imagesRights: RightsStatusType;
  filesRights: RightsStatusType;
  commentsRights: RightsStatusType;
  link: string;
  notes: string;
  properties: T;
}

export interface ChecklistSection {
  id: string;
  name: string;
  description: string;
  entries: ChecklistEntry[];
}
