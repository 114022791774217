import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DomainEntityBase } from '@fieldos/models';
import { DataSourceStore, StandardDataSourceId } from '@fieldos/store';
import { filterEmpty } from '@fieldos/utils';
import { TranslocoModule } from '@ngneat/transloco';
import { IFilterAngularComp } from 'ag-grid-angular';
import {
  AgPromise,
  IDoesFilterPassParams,
  IFilterParams,
} from 'ag-grid-community';

export interface DataSourceSetFilterParams
  extends IFilterParams<Record<string, any>, unknown> {
  dataSourceId: StandardDataSourceId;
  defaultExcludedValues?: number[];
}

@Component({
  selector: 'app-data-source-set-filter',
  templateUrl: './data-source-set-filter.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    AsyncPipe,
    TranslocoModule,
  ],
  styles: [
    `
      :host {
        max-height: 500px;
        @apply p-4 block;
      }
    `,
  ],
})
export class DataSourceSetFilterComponent implements IFilterAngularComp {
  constructor() {
    effect(
      () => {
        const dataSourceId = this._dataSourceId();
        if (dataSourceId) {
          const options = this._dataSourceStore.cache()[dataSourceId]?.data;

          if (options) {
            this._options.set(Object.values(options));
          }
        }
      },
      { allowSignalWrites: true }
    );
  }
  protected readonly searchCtrl = new FormControl('', {
    nonNullable: true,
  });

  protected readonly selection = signal<string[] | null>(null);

  protected readonly isSelectAllIntermediate = computed(() => {
    const selection = this.selection();

    if (!selection) {
      return false;
    }

    return selection.length !== 0 && selection.length < this._options().length;
  });

  protected readonly isSelectAllChecked = computed(
    () =>
      this.selection()?.length === this._options().length || !this.selection()
  );

  protected readonly options = computed(() => {
    const options = this._options();
    const filter = this._filterChange();

    return options.filter((option) =>
      option.name.toLowerCase().includes((filter || '').toLowerCase())
    );
  });

  private _params!: IFilterParams;

  private readonly _options = signal<DomainEntityBase[]>([]);
  private readonly _dataSourceId = signal<StandardDataSourceId | null>(null);
  private readonly _dataSourceStore = inject(DataSourceStore);

  private readonly _filterChange = toSignal(
    this.searchCtrl.valueChanges.pipe(filterEmpty())
  );
  private readonly _floatingFilterValue = signal<string | null>(null);

  onFloatingFilterChanged(value: string): void {
    this._floatingFilterValue.set(value);

    if (!value) {
      this.selection.set(null);
      this._params.filterChangedCallback();
      return;
    }

    const passedFilterValues = this._options().filter((e) =>
      e.name.toLowerCase().includes(value.toLowerCase())
    );

    this.selection.set(passedFilterValues.map((e) => `${e.id}`));
    this._params.filterChangedCallback();
  }

  agInit(params: DataSourceSetFilterParams): void {
    this._params = params;
    this._dataSourceId.set(params.dataSourceId);
  }

  isFilterActive(): boolean {
    return true;
  }

  doesFilterPass(
    params: IDoesFilterPassParams<Record<string, unknown>>
  ): boolean {
    if (this._params.colDef.field) {
      const columnValue = params.data[this._params.colDef.field];

      if (!this.selection()) {
        return true;
      }

      return !!this.selection()?.includes(`${columnValue}`);
    }

    return false;
  }

  getModel(): string | string[] | null {
    return this._floatingFilterValue() || this.selection();
  }

  setModel(model: string[]): void | AgPromise<void> {
    this.selection.set(model);
    this._params.filterChangedCallback();
  }

  getModelAsString?(model: string[]): string {
    return this.options()
      .filter((e) => model.includes(`${e.id}`))
      .map((e) => e.name)
      .join(', ');
  }

  clearModel?(): void {
    this.selection.set(null);
    this._params.filterChangedCallback();
  }

  protected onSelectAllChange(): void {
    this._floatingFilterValue.set(null);

    if (this.isSelectAllIntermediate() || this.selection()?.length === 0) {
      this.selection.set(null);
    } else {
      this.selection.set([]);
    }

    this._onFilterChange();
  }

  protected onOptionSelected(option: DomainEntityBase): void {
    this._floatingFilterValue.set(null);
    const value = this.selection();

    if (value === null) {
      const newSelection = this._options()
        .map((e) => `${e.id}`)
        .filter((e) => e !== `${option.id}`);

      this.selection.set(newSelection);
      this._onFilterChange();
      return;
    }

    if (value.includes(`${option.id}`)) {
      this.selection.set(value.filter((e) => e !== `${option.id}`));
      this._onFilterChange();
    } else {
      this.selection.set([...(this.selection() as []), `${option.id}`]);
    }
    this._onFilterChange();
  }

  private _onFilterChange(): void {
    this.setModel(this.selection() || []);
    this._params.filterChangedCallback();
  }
}
