import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  signal,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { TextFieldComponent } from '@fieldos/components';
import { DomainEntityBase, WorkOrderRoute } from '@fieldos/models';
import { RoutesStore } from '@fieldos/store/data-source-cache';
import { TranslocoModule } from '@ngneat/transloco';
import { IFilterAngularComp } from 'ag-grid-angular';
import {
  AgPromise,
  IDoesFilterPassParams,
  IFilterParams,
} from 'ag-grid-community';

@Component({
  selector: 'app-work-order-set-filter',
  templateUrl: './work-order-set-filter.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatCheckboxModule,
    TranslocoModule,
    TextFieldComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkOrderSetFilterComponent implements IFilterAngularComp {
  constructor() {
    effect(
      () => {
        const routes = this._store.entities();
        this.routes.set(routes);
      },
      { allowSignalWrites: true }
    );
  }

  protected readonly searchFormCtrl = new FormControl('', {
    nonNullable: true,
  });

  protected readonly routes = signal<WorkOrderRoute[]>([]);

  protected readonly selection = new SelectionModel<number>(true);
  protected get isSelectAllIntermediate(): boolean {
    return (
      this.selection.selected.length !== 0 &&
      this.selection.selected.length < this.routes().length
    );
  }

  protected get isSelectAllChecked(): boolean {
    return this.selection.selected.length === this.routes().length;
  }

  private _params!: IFilterParams;
  private readonly _store = inject(RoutesStore);
  private readonly _floatingFilterValue = signal<string | null>(null);

  onFloatingFilterChanged(value: string): void {
    this._floatingFilterValue.set(value);

    if (!value) {
      this.selection.setSelection(...this.routes().map((e) => e.id));
      this._params.filterChangedCallback();
      return;
    }

    const passedFilterValues = this._store
      .entities()
      .filter((e) => e.name.toLowerCase().includes(value.toLowerCase()));

    this.selection.setSelection(...passedFilterValues.map((e) => e.id));
    this._params.filterChangedCallback();
  }

  agInit(params: IFilterParams<Record<string, any>, unknown>): void {
    this._params = params;

    this._store.fetchAll().then(() => {
      const routes = this._store.entities();
      this.routes.set(routes);

      if (this.selection.selected.length === 0) {
        this.selection.setSelection(...routes.map((e) => e.id));
      }
    });
  }

  isFilterActive(): boolean {
    return true;
  }

  doesFilterPass(
    params: IDoesFilterPassParams<Record<string, unknown>>
  ): boolean {
    if (this._params.colDef.field) {
      const columnValue = params.data[this._params.colDef.field] as number;
      return this.selection.isSelected(columnValue);
    }

    return false;
  }

  getModel(): string | number[] {
    return this._floatingFilterValue() || this.selection.selected;
  }

  setModel(model: number[]): void | AgPromise<void> {
    this.selection.setSelection(...model);
  }

  getModelAsString?(model: number[]): string {
    return this.routes()
      .filter((e) => model.includes(e.id))
      .map((e) => e.name)
      .join(', ');
  }

  protected onSelectAllChange(event: MatCheckboxChange): void {
    this._floatingFilterValue.set(null);
    if (this.isSelectAllIntermediate || this.selection.selected.length === 0) {
      this.selection.setSelection(...this.routes().map((e) => e.id));
    } else {
      this.selection.clear();
    }
    this._onFilterChange();
  }

  protected onOptionSelected(option: DomainEntityBase): void {
    this._floatingFilterValue.set(null);
    this.selection.toggle(option.id);
    this._onFilterChange();
  }

  private _onFilterChange(): void {
    this.setModel(this.selection.selected);
    this._params.filterChangedCallback();
  }
}
