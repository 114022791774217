<div class="max-h-[420px] p-4 flex flex-col gap-4">
  <app-text-field
    [formControl]="searchFormCtrl"
    [placeholder]="'common.filter_options' | transloco"
    [type]="'text'" />

  <div class="flex flex-col overflow-auto">
    <mat-checkbox
      [checked]="isSelectAllChecked"
      [indeterminate]="isSelectAllIntermediate"
      (change)="onSelectAllChange($event)"
      color="primary">
      {{ 'common.select_all' | transloco }}
    </mat-checkbox>

    @for (option of routes(); track option.id) {
      <mat-checkbox
        [checked]="selection.isSelected(option.id)"
        (change)="onOptionSelected(option)"
        color="primary">
        {{ option.name }}
      </mat-checkbox>
    }
  </div>
</div>
