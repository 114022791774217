<div
  class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper"
  ref="eWrapper"
  role="presentation">
  <input
    id="ag-132-input"
    class="ag-input-field-input ag-text-field-input"
    [formControl]="searchFormCtrl"
    placeholder="Search..."
    ref="eInput"
    type="text" />
</div>
