import { inject, InjectionToken } from '@angular/core';
import {
  DataSourceSetFilterComponent,
  DataSourceSetFloatingFilterComponent,
  RatingCellRendererComponent,
  TextCellRendererComponent,
  TextFloatingFilterComponent,
} from '@fieldos/components';
import { Priority, SectionSubType } from '@fieldos/models';
import { PRIORITY_OPTIONS } from '@fieldos/providers';
import { StandardDataSourceId } from '@fieldos/store/data-source-cache';
import { TranslocoService } from '@ngneat/transloco';
import { ColDef, DateFilterParams, SetFilterParams } from 'ag-grid-community';
import { TextFilterComponent } from 'app/@components/ag-grid-renderers/text/text-filter/text-filter.component';
import {
  AssigneesCellRendererComponent,
  BooleanValueCellRendererComponent,
  DataSourceCellRendererComponent,
  DateCellRendererComponent,
  DurationCellRendererComponent,
  FilesCellRendererComponent,
  HtmlCellRedererComponent,
  LocationCellRendererComponent,
  PriorityCellRendererComponent,
  SingleDataSourceCellRendererComponent,
  StarFilterComponent,
  StarFloatingFilterComponent,
  StatusCellRendererComponent,
  WorkOrderSetCellRendererComponent,
  WorkOrderSetFilterComponent,
  WorkOrderSetFloatingFilterComponent,
} from './section-grid-renderers';
import { ShowInformationCellRendererComponent } from './section-grid-renderers/show-information-cell-renderer/show-information-cell-renderer.component';
import { StatusFloatingFilterComponent } from './section-grid-renderers/status';
import { StatusFilterComponent } from './section-grid-renderers/status/status-filter/status-filter.component';

export const SECTION_SUBTYPE_COL_DEF_MAP = new InjectionToken<
  Record<SectionSubType, ColDef>
>('SECTION_SUBTYPE_COL_DEF_MAP', {
  factory: (): Record<SectionSubType, ColDef> => {
    const defaultColDef = inject(DEFAULT_SECTION_COLUMN);
    const priorities = inject(PRIORITY_OPTIONS);
    const transloco = inject(TranslocoService);
    const defaultDataSourceColumn = inject(
      DEFAULT_DATA_SOURCE_COLUMN_GENERATOR
    );

    const dateCompare = (filterDate: Date, cellValue: string) => {
      const cellDate = new Date(cellValue);
      cellDate.setHours(0);
      cellDate.setMinutes(0);
      cellDate.setSeconds(0);

      if (cellDate < filterDate) {
        return -1;
      } else if (cellDate > filterDate) {
        return 1;
      }
      return 0;
    };

    return {
      [SectionSubType.Title]: {
        ...defaultColDef,
      },
      [SectionSubType.Priority]: {
        ...defaultColDef,
        minWidth: 120,
        cellRenderer: PriorityCellRendererComponent,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: priorities.map((e) => e.value),
          valueFormatter: (params) =>
            transloco.translate(
              priorities.find((e) => e.value === params.value)?.label || ''
            ),
        } as SetFilterParams<Priority, Priority>,
      },
      [SectionSubType.Description]: {
        ...defaultColDef,
        cellRenderer: HtmlCellRedererComponent,
      },
      [SectionSubType.SolvingDescription]: {
        ...defaultColDef,
        cellRenderer: HtmlCellRedererComponent,
      },
      [SectionSubType.Location]: {
        ...defaultColDef,
        cellRenderer: LocationCellRendererComponent,
      },
      [SectionSubType.Type]: {
        ...defaultDataSourceColumn,
        filterParams: {
          dataSourceId: StandardDataSourceId.Types,
        },
      },
      [SectionSubType.Customer]: {
        ...defaultDataSourceColumn,
        filterParams: {
          dataSourceId: StandardDataSourceId.Customers,
        },
      },
      [SectionSubType.CustomerContract]: {
        ...defaultDataSourceColumn,
        filterParams: {
          dataSourceId: StandardDataSourceId.CustomerContracts,
        },
      },

      [SectionSubType.StartBy]: {
        ...defaultColDef,
        cellRenderer: DateCellRendererComponent,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateCompare,
        } as DateFilterParams,
      },
      [SectionSubType.LoadByDate]: {
        ...defaultColDef,
        cellRenderer: DateCellRendererComponent,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateCompare,
        } as DateFilterParams,
      },
      [SectionSubType.DueBy]: {
        ...defaultColDef,
        cellRenderer: DateCellRendererComponent,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateCompare,
        } as DateFilterParams,
      },
      [SectionSubType.Assignees]: {
        ...defaultColDef,
        cellRenderer: AssigneesCellRendererComponent,
      },
      [SectionSubType.Teams]: {
        ...defaultDataSourceColumn,
        filterParams: {
          dataSourceId: StandardDataSourceId.Teams,
        },
      },
      [SectionSubType.Asset]: {
        ...defaultDataSourceColumn,
        filterParams: {
          dataSourceId: StandardDataSourceId.Assets,
        },
      },
      [SectionSubType.ResponsiblePerson]: {
        ...defaultDataSourceColumn,
        filterParams: {
          dataSourceId: StandardDataSourceId.Assignees,
        },
      },
      [SectionSubType.StartingLocation]: {
        ...defaultDataSourceColumn,
        filterParams: {
          dataSourceId: StandardDataSourceId.Locations,
        },
      },
      [SectionSubType.Parts]: defaultColDef,
      [SectionSubType.Status]: {
        ...defaultColDef,
        filter: StatusFilterComponent,
        cellRenderer: StatusCellRendererComponent,
        floatingFilterComponent: StatusFloatingFilterComponent,
      },

      [SectionSubType.Rating]: {
        ...defaultColDef,
        cellRenderer: RatingCellRendererComponent,
        filter: StarFilterComponent,
        floatingFilterComponent: StarFloatingFilterComponent,
      },
      [SectionSubType.Identifier]: {
        ...defaultColDef,
        filter: 'agNumberColumnFilter',
        minWidth: 120,
      },
      [SectionSubType.Container]: defaultColDef,
      [SectionSubType.EstimatedDuration]: {
        ...defaultColDef,
        cellRenderer: DurationCellRendererComponent,
      },
      [SectionSubType.SingleCustomDataSource]: {
        ...defaultColDef,
        cellRenderer: SingleDataSourceCellRendererComponent,
        sortable: false,
        filter: DataSourceSetFilterComponent,
      },
      [SectionSubType.SingleStandardDataSource]: {
        ...defaultColDef,
        sortable: false,
        cellRenderer: SingleDataSourceCellRendererComponent,
      },
      [SectionSubType.Supervisors]: {
        ...defaultDataSourceColumn,
        filterParams: {
          dataSourceId: StandardDataSourceId.Users,
        },
      },
      [SectionSubType.ShowInformation]: {
        ...defaultColDef,
        cellRenderer: ShowInformationCellRendererComponent,
      },
      [SectionSubType.Media]: {
        ...defaultColDef,
        filter: undefined,
        cellRenderer: FilesCellRendererComponent,
      },
      [SectionSubType.Documents]: {
        ...defaultColDef,
        filter: undefined,
        cellRenderer: FilesCellRendererComponent,
      },
      [SectionSubType.Signature]: {
        ...defaultColDef,
        cellRenderer: FilesCellRendererComponent,
        filter: undefined,
      },
      [SectionSubType.Set]: {
        ...defaultColDef,
        cellRenderer: WorkOrderSetCellRendererComponent,
        filter: WorkOrderSetFilterComponent,
        floatingFilterComponent: WorkOrderSetFloatingFilterComponent,
      },
      [SectionSubType.ConfirmLocation]: {
        ...defaultColDef,
        filter: false,
        floatingFilter: false,
        cellRenderer: BooleanValueCellRendererComponent,
      },
      [SectionSubType.DPU]: {
        ...defaultColDef,
        filter: false,
        floatingFilter: false,
      },
      [SectionSubType.ShowSealWithConfirmation]: {
        ...defaultColDef,
        filter: false,
        floatingFilter: false,
      },
      [SectionSubType.InputSealWithConfirmation]: {
        ...defaultColDef,
        filter: false,
        floatingFilter: false,
      },
      [SectionSubType.Seal]: {
        ...defaultColDef,
        cellRenderer: TextCellRendererComponent,
        filter: false,
        floatingFilter: false,
      },
      [SectionSubType.PackagingLoaded]: {
        ...defaultColDef,
        cellRenderer: BooleanValueCellRendererComponent,
        filter: false,
        floatingFilter: false,
      },
      [SectionSubType.PackagingUnloaded]: {
        ...defaultColDef,
        cellRenderer: BooleanValueCellRendererComponent,
        filter: false,
        floatingFilter: false,
      },
      [SectionSubType.PackagingLeft]: {
        ...defaultColDef,
        cellRenderer: BooleanValueCellRendererComponent,
        filter: false,
        floatingFilter: false,
      },
      [SectionSubType.ConfirmEmtpy]: {
        ...defaultColDef,
        cellRenderer: BooleanValueCellRendererComponent,
        filter: false,
        floatingFilter: false,
      },
    };
  },
});

export const DEFAULT_SECTION_COLUMN = new InjectionToken(
  'DEFAULT_SECTION_COLUMN',
  {
    factory: (): ColDef => ({
      floatingFilter: true,
      cellRenderer: TextCellRendererComponent,
      filter: TextFilterComponent,
      floatingFilterComponent: TextFloatingFilterComponent,
      minWidth: 200,
      resizable: true,
      sortable: true,
      initialHide: false,
      enablePivot: true,
      autoHeight: false,
      comparator: (valueA, valueB) => {
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA
            .trim()
            .toLowerCase()
            .localeCompare(valueB.trim().toLowerCase());
        }

        return valueA - valueB;
      },
    }),
  }
);

export const DEFAULT_DATA_SOURCE_COLUMN_GENERATOR = new InjectionToken(
  'DEFAULT_DATA_SOURCE_COLUMN_GENERATOR',
  {
    factory: (): ColDef => {
      const defaultCol = inject(DEFAULT_SECTION_COLUMN);

      return {
        ...defaultCol,
        cellRenderer: DataSourceCellRendererComponent,
        filter: DataSourceSetFilterComponent,
        floatingFilterComponent: DataSourceSetFloatingFilterComponent,
        sortable: false,
      };
    },
  }
);
