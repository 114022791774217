import { Route } from '@angular/router';
import { AuthGuard, ClaimsGuard, NoAuthGuard } from '@fieldos/core';
import { LayoutComponent } from 'app/layout/layout.component';
import { administrationRoutes } from './modules';
import { assetRoutes } from './modules/assets/assets.routes';
import { authRoutes } from './modules/auth';
import { contractorRoutes } from './modules/contractors/contractors.routes';
import { errorRoutes } from './modules/errors/error.routes';
import { locationRoutes } from './modules/locations';
import { pagesRoutes } from './modules/pages/pages.routes';
import { requestsRoutes } from './modules/requests';
import { workOrdersRoutes } from './modules/work-orders';
import { userTeamRoutes } from './modules/workforce';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  ...pagesRoutes,

  {
    path: 'auth/return',
    loadComponent: () =>
      import('app/modules/auth/auth-return/auth-return.component').then(
        (m) => m.AuthReturnComponent
      ),
  },
  {
    path: 'reset-password/for-user/:token',

    loadComponent: () =>
      import('./modules/auth/reset-password/reset-password.component').then(
        (m) => m.ResetPasswordComponent
      ),
    data: {
      forUser: true,
      layout: 'empty',
    },
  },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [...authRoutes],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard, ClaimsGuard],
    data: {
      layout: 'classic',
    },
    children: [
      ...workOrdersRoutes,
      ...requestsRoutes,
      ...userTeamRoutes,
      ...locationRoutes,
      ...assetRoutes,
      ...contractorRoutes,
      ...errorRoutes,
      {
        path: 'home',
        loadComponent: () =>
          import('./modules/homepage/homepage.component').then(
            (m) => m.HomepageComponent
          ),
        data: {
          title: 'menu.homepage',
        },
      },
      ...administrationRoutes,
      {
        path: 'dashboard',
        loadComponent: () =>
          import('./modules/dashboard/dashboard.component').then(
            (m) => m.DashboardComponent
          ),
        data: {
          title: 'menu.dashboard',
        },
      },
      {
        path: 'inbox',
        loadComponent: () =>
          import('./modules/inbox/inbox.component').then(
            (m) => m.InboxComponent
          ),
        data: {
          title: 'menu.inbox',
        },
      },
      {
        path: 'planner',
        loadComponent: () =>
          import('./modules/planner/planner.component').then(
            (m) => m.PlannerComponent
          ),
        data: {
          title: 'menu.planner',
        },
      },
      {
        path: 'plans',
        loadComponent: () =>
          import('./modules/plans/plans.component').then(
            (m) => m.PlansComponent
          ),
        data: {
          title: 'menu.plans',
        },
      },
      {
        path: 'reports',
        loadComponent: () =>
          import('./modules/reports/reports.component').then(
            (m) => m.ReportsComponent
          ),
        data: {
          title: 'menu.reports',
        },
      },
    ],
  },
  { path: '**', redirectTo: '/home' },
];
